<template>
    <div class="ant-spin-container">
        <div class="result_wrap">
            <div class="title">受众概览</div>
            <div style="text-align: center;">
                <canvas width="340" height="220" ref="gauge" style="width: 170px; height: 110px;"></canvas>
            </div>
        </div>
        <div class="result_wrap_numbers">
            <div style="text-align: center;">预估覆盖人群<br><strong style="font-size: 28px;"
                                                               :style="{color:usersStage.color}"><span
                    :title="users">{{usersStage.usersWithUnit(users)}}</span></strong><span
                    style="font-size: 23px; font-weight: 400; margin-left: 5px;"
                    :style="{color:usersStage.color}">人</span>
                <div></div>
            </div>
            <div style="text-align: center;"><img :src="usersStage.icon" alt="受众图标" style="width: 30px;height: 30px;"></div>
        </div>
    </div>
</template>

<script>
    import imgLot from './targeting-lot.svg'
    import imgLow from './targeting-low.svg'
    import imgMid from './targeting-mid.svg'

    function UsersStage(icon, color) {
        this.icon = icon;
        this.color = color;
        this.usersWithUnit = function (users) {
            if (users < 10e3) return users;
            if (users < 10e7) return (users / 10e3).toFixed(0) + '万';
            return (users / 10e7).toFixed(0) + '亿';
        };
    }

    const stages = [
        new UsersStage(imgLow, 'rgb(245, 89, 73)'),
        new UsersStage(imgMid, 'rgb(0, 183, 148)'),
        new UsersStage(imgLot, 'rgb(239, 131, 0)')
    ];

    export default {
        name: "DrawGauge",
        props: {
            users: {
                type: Number,
                required: true
            }
        },
        computed: {
            usersStage() {
                let c = this.users;
                if (c) {
                    if (c < 2e4) return stages[0];
                    if (c < 8e6) return stages[1];
                }
                return stages[2];
            },
        },
        mounted() {
            this.drawGauge();
        },
        updated() {
            this.drawGauge();
        },
        methods: {
            drawGauge: function () {
                var target = this.$refs.gauge;
                var a = this.users || 0,
                    n = 21e8,
                    i = a > n ? n : a,
                    r = 1;
                r = i <= 2e4 ? i / 2e4 * .16 + r : i <= 8e6 ? (i - 2e4) / 798e4 * .66 + r + .16 : (i - 8e6) / 2092e6 * .16 + r + .83;
                var o = target;
                if (o) {
                    var l = o.getContext("2d");
                    if (l) {
                        l.clearRect(0, 0, o.width, o.height), l.lineWidth = 18, l.beginPath(), l.strokeStyle = "#BD413A", l.arc(170, 180, 130, 1 * Math.PI, 1.16 * Math.PI), l.stroke(), l.beginPath(), l.strokeStyle = "#68A567", l.arc(170, 180, 130, 1.16 * Math.PI, 1.83 * Math.PI), l.stroke(), l.beginPath(), l.strokeStyle = "#F5D3AA", l.arc(170, 180, 130, 1.83 * Math.PI, 0 * Math.PI), l.stroke(), l.closePath(), l.lineWidth = 5, l.font = "24px Arial", l.fillStyle = "#8c8c8c", l.fillText("\u5177\u4f53", 20, 210), l.fillText("\u5bbd\u6cdb", 280, 210), l.beginPath(), l.strokeStyle = "#141922", l.arc(170, 180, 10, 0, 2 * Math.PI), l.stroke(), l.beginPath();
                        var c = r * Math.PI;
                        l.moveTo(170 + 10 * Math.cos(c), 180 + 10 * Math.sin(c)), l.lineTo(170 + 70 * Math.cos(c), 180 + 70 * Math.sin(c)), l.stroke();
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .title{
        text-align: center;
    }

</style>