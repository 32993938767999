<template>
    <span>
        <el-tooltip  placement="right" title="" trigger="hover"  :enterable="true" effect="light">
            <div class="tipsContent"  slot="content" style="width:260px">
                <p>受众群体范围示例</p>
                <div class="tip">({{tipsList[type].tips}})</div>
                <el-image :src="tipsList[type].img"></el-image>
                <div class="example">
                    <p>例如：</p>
                    <span v-html="tipsList[type].con"></span>
                </div>
            </div>
            <i class="el-icon-info"></i>
        </el-tooltip>
    </span>
</template>
<script>
    // tips1Svg:require('@/assets/icon/audience1.svg'),
    //             tips2Svg:require('@/assets/icon/audience2.svg'),
    //             tips3Svg:require('@/assets/icon/audience3.svg'),
    export default {
        props:['type'],
        data() {
            return {
                tipsList:{
                    first:{
                        tips:'此规则适用于任何选定类型间的组合',
                        img:require('@/assets/icon/audience1.svg'),
                        con:'您选择了兴趣类型中的“篮球”标签和行为类型中的“登山者”标签。</br>那么，受众A群体就包含了对“篮球”或“登山者”以及对两者都感兴趣的三类人群。'
                    },
                    second:{
                        tips:'此规则缩小了之前规则创建的受众范围',
                        img:require('@/assets/icon/audience2.svg'),
                        con:'如果仅将人口统计选项“90后” 添加到该细分受众群，则最终的受众将是先前规则与90后的人的交集。'
                    },
                    third:{
                        tips:'此规则更加缩小了之前规则创建的受众范围',
                        img:require('@/assets/icon/audience3.svg'),
                        con:'如果再次将人口选项中的“6月生日”添加到该细分受众，则最终的受众将是先前规则和“6月生日”的人差集。'
                    }
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .tipsContent{
        text-align: center;
        p{
            line-height: 3;
            color: #409eff;
        }
        .tip{
            font-size: 12px;
            color: #666;
        }
        .el-image{
            margin: 10px auto;
        }
        .example{
            text-align: left;
            font-size: 12px;
            color: #999;
        }
    }
</style>