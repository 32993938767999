// 判断数组中是否存在重复的值
import { newUpload, saveMedia } from '@/api/creatAd.js'
export default {
  methods: {
    isRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) return true;
        hash[arr[i]] = true;
      }
      return false;
    },
    getValidUrl(url) {
      console.log(url);
      let xieyi = url.indexOf('https://') >= 0 ? 'https://' : 'http://';
      console.log(xieyi);
      console.log(url.lastIndexOf(xieyi));
      let str = url.substring(xieyi.length);
      return str;
    },
    previewBefore(fbAssetFeedSpec) {
      fbAssetFeedSpec.imgList = (fbAssetFeedSpec.imgList || []).filter((item) => !!item.url);
      fbAssetFeedSpec.videos = (fbAssetFeedSpec.videos || []).filter((item) => !!item.url);
      fbAssetFeedSpec.mBodies = (fbAssetFeedSpec.mBodies || []).filter((item) => !!item);
      let list = fbAssetFeedSpec.imgList.concat(fbAssetFeedSpec.videos);
      if (
        !fbAssetFeedSpec.mAdFormats.length ||
        !fbAssetFeedSpec.titles.length ||
        !fbAssetFeedSpec.mDescriptions.length ||
        !fbAssetFeedSpec.mBodies.length ||
        !fbAssetFeedSpec.actionTypes.length ||
        !fbAssetFeedSpec.linkUrls.length ||
        !list.length
      ) {
        return false;
      }
      if (
        fbAssetFeedSpec.imgList &&
        fbAssetFeedSpec.imgList.length &&
        this.isRepeat(fbAssetFeedSpec.imgList.map((v) => v.source_id))
      ) {
        this.$message({
          type: 'warning',
          message: '动态广告图片素材不能重复。',
        });
        return false;
      }
      if (
        fbAssetFeedSpec.videos &&
        fbAssetFeedSpec.videos.length &&
        this.isRepeat(fbAssetFeedSpec.videos.map((v) => v.source_id))
      ) {
        this.$message({
          type: 'warning',
          message: '动态广告视频素材不能重复。',
        });
        return false;
      }
      if (this.isRepeat(fbAssetFeedSpec.titles)) {
        this.$message({
          type: 'warning',
          message: '动态广告标题不能重复。',
        });
        return false;
      }
      if (this.isRepeat(fbAssetFeedSpec.mDescriptions)) {
        this.$message({
          type: 'warning',
          message: '动态广告描述不能重复。',
        });
        return false;
      }
      if (this.isRepeat(fbAssetFeedSpec.mBodies)) {
        this.$message({
          type: 'warning',
          message: '动态广告正文不能重复。',
        });
        return false;
      }
      if (this.isRepeat(fbAssetFeedSpec.actionTypes)) {
        this.$message({
          type: 'warning',
          message: '动态广告行动号召不能重复。',
        });
        return false;
      }
      if (this.isRepeat(fbAssetFeedSpec.linkUrls)) {
        this.$message({
          type: 'warning',
          message: '动态广告推广链接不能重复。',
        });
        return false;
      }
      return true;
    },
    validCreative(creative, aderiesInfo) {
      console.log(aderiesInfo);
      let msg = '',
        flag = true;
      if (!(aderiesInfo.objective == 'PAGE_LIKES' || aderiesInfo.objective == 'POST_ENGAGEMENT')) {
        if (!creative.page_id) {
          flag = false;
          msg = '请选择主页';
        }
        if (creative.media_type == 'image' || creative.media_type == 'video') {
          // console.log(creative);

          if (
            creative.image &&
            creative.action_type &&
            creative.message &&
            creative.web_url &&
            this.getValidUrl(creative.web_url)
          ) {
            if (creative.media_type == 'video' && creative.videoStatus != 'ready') {
              msg = '请等待视频上传fb';
              flag = false;
            }
          } else {
            msg = '请检查广告素材，行动号召，正文，投放链接是否完整';
            flag = false;
          }
        } else if (creative.media_type == 'muti') {
          let list = JSON.parse(JSON.stringify(creative.web_url && creative.mutiImgs));
          let imgList = list.slice(0, creative.mutiImgs.length - 1);
          if (imgList.length < 2) {
            flag = false;
            msg = '请至少上传两个轮播图';
          }
          if (
            creative.action_type &&
            creative.message &&
            imgList.every((v) => v.image) &&
            imgList.every((v) => v.link_url && this.getValidUrl(v.link_url))
          ) {
          } else {
            flag = false;
            msg = '请检查轮播图是否完整';
          }
        } else if (creative.media_type == 'post') {
          if (!creative.post_id) {
            msg = '请选择帖子';
            flag = false;
          }
        } else if (creative.media_type == 'asset_feed') {
          if (!creative.fbAssetFeedSpec) {
            msg = '请检查动态广告';
            flag = false;
          }
          let fbAssetFeedSpec = creative.fbAssetFeedSpec || {};
          fbAssetFeedSpec.actionTypes = fbAssetFeedSpec.actionTypes.filter((v) => v);
          fbAssetFeedSpec.linkUrls = fbAssetFeedSpec.linkUrls.filter((v) => v);
          // console.log(this.previewBefore(fbAssetFeedSpec));
          if (!this.previewBefore(fbAssetFeedSpec)) {
            flag = false;
          }
        } else {
          flag = false;
        }

        //
        if (creative.media_type == 'muti') {
          console.log(creative.mutiImgs);
          creative.mutiImgs = creative.mutiImgs[creative.mutiImgs.length - 1].link_url
            ? creative.mutiImgs.slice(0)
            : creative.mutiImgs.slice(0, creative.mutiImgs.length - 1);
        }
        if (creative.media_type !== 'asset_feed') {
          creative.fbAssetFeedSpec = null;
        }
      } else {
        if (!creative.page_id) {
          msg = '请选择主页';
          flag = false;
        }
        if (creative.media_type == 'image' || creative.media_type == 'video') {
          // console.log(creative);
          if (creative.image && creative.message) {
            if (creative.media_type == 'video' && creative.videoStatus != 'ready') {
              msg = '请等待视频上传fb';
              flag = false;
            }
            if (aderiesInfo.objective !== 'POST_ENGAGEMENT') {
              creative.web_url = null;
            }
          } else {
            msg = '请上传素材并填写正文';
            flag = false;
          }
        }
        // 贴文互动
        if (aderiesInfo.objective == 'POST_ENGAGEMENT' && creative.media_type !== 'post') {
          if (creative.action_type) {
            if (this.hasLink && !creative.web_url) {
              msg = '请输入投放链接';
              flag = false;
            }
          } else {
            msg = '请选择行动号召';
            flag = false;
          }
        } else if (aderiesInfo.objective == 'POST_ENGAGEMENT' && creative.media_type == 'post') {
          if (!creative.post_id) {
            msg = '请选择帖子';
            flag = false;
          }
        }
      }
      return { creative: creative, flag: flag, msg: msg };
    },
    validAdLists(adlist, aderiesInfo) {
      let msg = '',
        linkMsg = '(推广网址中的域名必须与第一步推广网址中的域名保持一致)',
        flag = true;
      console.log(adlist);
      adlist.map((item, index) => {
        let indexMsg = `请检查第${index + 1}个广告`;
        if (aderiesInfo.business !== 'FACEBOOK_PAGE') {
          if (!item.page_id) {
            msg = indexMsg + '主页是否完整';
            flag = false;
          }
          if (item.media_type == 'image' || item.media_type == 'video') {
            if (item.image) {
              if (item.media_type == 'video' && item.videoStatus != 'ready') {
                msg = '请等待视频成功上传fb,在进行下一步';
                flag = false;
              }
            } else {
              msg = indexMsg + '素材是否完整';
              flag = false;
            }
          } else if (item.media_type == 'muti') {
            let list = JSON.parse(JSON.stringify(item.mutiImgs.slice(0, item.mutiImgs.length - 1)));
            if (list.length < 2) {
              msg = '轮播图至少两个';
              flag = false;
            } else if (list.every((v) => v.image && v.link_url)) {
              if (!list.every((v) => this.getValidUrl(v.link_url)) || !list.some((v) => this.checkUrl(v.link_url))) {
                msg = '轮播图创意网址有误，请检查' + linkMsg;
                flag = false;
              }
            } else {
              msg = indexMsg + '素材不完整';
              flag = false;
            }
          } else if (item.media_type == 'post') {
            if (!item.post_id) {
              msg = indexMsg + '帖子Id是否完整';
              flag = false;
            }
          }

          if (item.media_type != 'post' && item.media_type != 'asset_feed') {
            if (!item.action_type) {
              msg = indexMsg + '行动号召是否完整';
              flag = false;
            }
            if (!item.message) {
              msg = indexMsg + '正文是否完整';
              flag = false;
            }
            if (item.web_url && !this.checkUrl(item.web_url)) {
              msg = indexMsg + '推广网址' + linkMsg;
              flag = false;
            } else if (!item.web_url) {
              msg = indexMsg + '推广网址是否完整';
              flag = false;
            }
          }
          if (item.media_type == 'asset_feed') {
            let fbAssetFeedSpec = item.fbAssetFeedSpec || {};
            fbAssetFeedSpec.actionTypes = fbAssetFeedSpec.actionTypes.filter((v) => v);
            fbAssetFeedSpec.linkUrls = fbAssetFeedSpec.linkUrls.filter((v) => v);
            let imgList = fbAssetFeedSpec.imgList || [];
            let videos = fbAssetFeedSpec.videos || [];
            let list = imgList.concat(videos);
            if (
              !fbAssetFeedSpec.mAdFormats ||
              !list.length ||
              !fbAssetFeedSpec.titles.length ||
              !fbAssetFeedSpec.mDescriptions.length ||
              !fbAssetFeedSpec.mBodies.length ||
              !fbAssetFeedSpec.actionTypes.length ||
              !fbAssetFeedSpec.linkUrls.length
            ) {
              msg = '动态广告的数据不完整，每一项至少有包含一个值';
              flag = false;
            }
            flag = this.previewBefore(fbAssetFeedSpec);
          }
        } else {
          if (!item.page_id) {
            msg = indexMsg + '主页信息是否完整';
            flag = false;
          }
          if (item.media_type == 'image' || item.media_type == 'video') {
            if (item.image) {
              if (item.media_type == 'video' && item.videoStatus != 'ready') {
                flag = false;
              }
            } else {
              msg = indexMsg + '素材信息是否完整';
              flag = false;
            }
            if (!item.message) {
              msg = indexMsg + '正文是否完整';
              flag = false;
            }
            // 贴文互动
            if (aderiesInfo.objective == 'POST_ENGAGEMENT' && item.media_type !== 'post') {
              if (item.action_type) {
                if (this.hasLink && !item.web_url) {
                  msg = indexMsg + '投放链接是否完整';
                  flag = false;
                }
              } else {
                msg = indexMsg + '行动号召是否完整';
                flag = false;
              }
            } else if (aderiesInfo.objective == 'POST_ENGAGEMENT' && item.media_type == 'post') {
              if (!item.post_id) {
                msg = indexMsg + '帖子id是否完整';
                flag = false;
              }
            }
          }
        }
      });
      return { flag, msg };
    },
    // 批量图片，批量视频数据处理
    defaultTitle(imgList) {
        return imgList.find(v => v.copywriter && v.copywriter.title)?.copywriter?.title
    },
    defaultContent(imgList) {
        return imgList.find(v => v.copywriter && v.copywriter.content)?.copywriter?.content
    },
    defaultMessage(imgList) {
        return imgList.find(v => v.message)?.message
    },
    // 多视频下一步，给广告文案默认值
    
    // 创意多图，多视频拆分
    formatCreativeList(adList,aderiesInfo) {
      let list = []
      adList.forEach(creative => {
        if (aderiesInfo.business == 'FACEBOOK_PAGE' || (creative.media_type == 'muti' || creative.media_type == 'asset_feed' || creative.media_type == 'post')) {
          list.push(creative)
        } else {
          let imgListNew = JSON.parse(JSON.stringify(creative.imgList || []));
          let arr = [];
          imgListNew.forEach((item,index) => {
            let msg = { copywriter: item.copywriter, message: item.message }
            if (!msg.copywriter) {
              msg.copywriter = {
                title: this.defaultTitle(imgListNew),
                content: this.defaultContent(imgListNew)
              }
            } else {
              msg.copywriter = {
                title: msg.copywriter.title || this.defaultTitle(imgListNew),
                content: msg.copywriter.content || this.defaultContent(imgListNew)
              }
            }
            msg.message = msg.message || this.defaultMessage(imgListNew)
            let {imgList,...newCreative} = creative
            arr.push({ ...newCreative, image: item.url, source_id: item.id, thumbUrl: item.thumbUrl, ...msg, videoStatus: item.videoStatus ,name: creative.name + '_' + (index+1)})
          })
          list = list.concat(arr)
        }
      })
      return list
    },
    validAdsInfo(adsInfo, objective) { },
    /**
     * 设置 localStoryage
     * &#64;param key 键
     * &#64;param value 值
     * &#64;param expire 过期时间(秒) 缓存最大时间请勿超过 forceTime 否则每次都会清空数据 (未设置请忽略)
     * &#64;return bool true
     */
    cacheSet(key, value, expire) {
      // 当前时间戳
      var nowTime = Math.ceil(Date.now() / 1000);
      // 设置数据
      localStorage.setItem(key, JSON.stringify(value));
      // 判断是否有过期时间
      if (expire > 0) {
        // 设置过期时间
        localStorage.setItem(key + '_expire', nowTime + parseInt(expire));
      } else {
        // 设置过期时间
        localStorage.setItem(key + '_expire', '');
      }
      return true;
    },
    /**
     * 读取 localStoryage
     * &#64;param key
     * &#64;return bool|originData false|originData
     */
    cacheGet(key) {
      // 当前时间戳
      var nowTime = Math.ceil(Date.now() / 1000);
      // 获取键时间戳
      var rawCacheDataExpire = localStorage.getItem(key + '_expire');
      var cacheDataExpire = parseInt(rawCacheDataExpire);

      // 强制过期时间 为0时忽略 用于解决缓存时间与本地时间差距过大(例本地更改了计算机时间)
      var forceTime = 3600;
      // 判断用户是否删除了过期时间 判断是否设置了过期时间 判断是否超过过期时间 判断当前计算机时间与设置的过期时间差距是否过大
      if (
        rawCacheDataExpire === null ||
        (cacheDataExpire > 0 &&
          (nowTime > cacheDataExpire || (forceTime > 0 && Math.abs(cacheDataExpire - nowTime) > forceTime)))
      ) {
        // 删除过期key
        localStorage.removeItem(key);
        // 删除过期时间
        localStorage.removeItem(key + '_expire');
        return false;
      }
      // 获取数据
      let cacheData = JSON.parse(localStorage.getItem(key));
      // 返回数据
      return cacheData || false;
    },
    // 新上传素材逻辑
    // 素材保存接口
    async saveMedia(data) {
      return await saveMedia(data).then(res => {
        return res;
      })
    },
    newUpload(formData, data) {
      return newUpload(formData, data).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "上传成功"
          });
          return this.saveMedia(res.data).then(res1 => {
            return res1
          })
          // return ;
        }
      });
    },
  },
};
